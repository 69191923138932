@import "~font-awesome/less/font-awesome";

@font-face {
  font-family: "Avenir LT W01_35 Light";
  src: url("./fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix");
  src: url("./fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix") format("eot"),
    url("./fonts/c4352a95-7a41-48c1-83ce-d8ffd2a3b118.woff") format("woff"),
    url("./fonts/3e419b5b-c789-488d-84cf-a64009cc308e.ttf") format("truetype"),
    url("./fonts/ca038835-1be3-4dc5-ba25-be1df6121499.svg#ca038835-1be3-4dc5-ba25-be1df6121499")
      format("svg");
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-regular-webfont.woff") format("woff"),
    url("./fonts/proximanova-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-bold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-bold-webfont.woff") format("woff"),
    url("./fonts/proximanova-bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/proximanova-italic-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-italic-webfont.woff") format("woff"),
    url("./fonts/proximanova-italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("./fonts/effra_std_he-webfont.woff2") format("woff2"),
    url("./fonts/effra_std_he-webfont.woff") format("woff"),
    url("./fonts/effra_std_he-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/averta-thin-webfont.woff2") format("woff2"),
    url("./fonts/averta-thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/averta-regular-webfont.woff2") format("woff2"),
    url("./fonts/averta-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/averta-semibold-webfont.woff2") format("woff2"),
    url("./fonts/averta-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/averta-bold-webfont.woff2") format("woff2"),
    url("./fonts/averta-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/averta-black-webfont.woff2") format("woff2"),
    url("./fonts/averta-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@main-font: "Averta", Helvetica, sans-serif;

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
button,
.form-control {
  font-family: @main-font;
}

.checkbox label {
  font-family: @main-font !important;
}

b {
  font-weight: 900;
}

@font-family-sans-serif: @main-font;
// @headings-font-family: 'Avenir LT W01_35 Light', Helvetica, Arial, sans-serif;
@headings-font-family: @main-font;
